import { Spin, Modal } from "antd";
import React, { useReducer } from "react";

const initialState = {
  content: undefined,
  visible: false,
  styleName: undefined,
  title: undefined,
  closable: true,
  backpress: true,
  spinning: false,
  callBack: undefined,
};

function ModalContextImpl(value) {
  let state = { ...value };
  let dispatch = undefined;

  const iniReducer = (action) => {
    if (!action || action.length < 0) {
      throw new Error("reduce not implemented.");
    }

    state = action[0];
    dispatch = action[1];
  };

  const reducer = (state, action) => {
    return action;
  };

  const isVisible = () => {
    return state.visible;
  };

  const isBackPress = () => {
    return state.backPress;
  };

  const isClosable = () => {
    return state.closable;
  };

  const isSpinning = () => {
    return state.spinning;
  };

  const hasStyleName = () => {
    const hasStyleName = state.styleName ? true : false;
    return hasStyleName;
  };

  const getStyleName = () => {
    return state.styleName;
  };

  const getContent = () => {
    return state.component;
  };

  const getTitle = () => {
    return state.title;
  };

  const setSpinning = (value) => {
    const afterState = { ...state, spinning: value };
    dispatch && dispatch(afterState);
  };

  const setBackPress = (value) => {
    const afterState = { ...state, backPress: value };
    dispatch && dispatch(afterState);
  };

  const show = (
    content,
    closable = true,
    backpress = true,
    styleName = undefined,
    title = undefined,
    callBack = undefined
  ) => {
    const value = {
      component: content,
      title: title,
      closable: closable,
      backPress: backpress,
      styleName: styleName,
      callBack: callBack,
      visible: true,
    };
    dispatch && dispatch(value);
  };

  const hide = (feedBack) => {
    const { callBack } = state;

    if (feedBack && callBack) {
      callBack();
    }

    dispatch && dispatch(initialState);
  };

  return {
    iniReducer: iniReducer,
    reducer: reducer,
    isVisible: isVisible,
    isBackPress: isBackPress,
    isClosable: isClosable,
    isSpinning: isSpinning,
    hasStyleName: hasStyleName,
    getStyleName: getStyleName,
    getTitle,
    getContent: getContent,
    setSpinning: setSpinning,
    setBackPress: setBackPress,
    show: show,
    hide: hide,
  };
}

const provider = new ModalContextImpl(initialState);

export const ModalContext = React.createContext(provider);

const AppModal = () => {
  provider.iniReducer(useReducer(provider.reducer, initialState));

  return (
    <ModalContext.Consumer>
      {(context) =>
        context.isVisible() ? (
          <Modal
            width={1500}
            style={{padding: 80}}
            title={context.getTitle()}
            centered
            visible={true}
            closable={context.isClosable()}
            onOk={() => {
              context.isBackPress() && context.hide(true);
            }}
            onCancel={() => {
              context.isBackPress() && context.hide(true);
            }}
            footer={null}
            className={
              "app-modal" +
              (context.hasStyleName() ? ` ${context.getStyleName()}` : "")
            }
          >
            {context.isSpinning() ? (
              <div className="loader">
                <Spin />
              </div>
            ) : (
              context.getContent()
            )}
          </Modal>
        ) : (
          <></>
        )
      }
    </ModalContext.Consumer>
  );
};

export default AppModal;
