import { NavLink } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';

const NMenu = (props) => {
    const { authState } = useSelector((state) => state.core);

    let { activeStyle, source } = props;

    if (!source) {
        source = new Array();
    }

    const getRoles = () => {
        console.log("userInfo: ", authState?.userInfo);
        if (authState?.userInfo?.resource_access) {
            const key = Object.keys(authState?.userInfo?.resource_access)[0];
            if (key) {
                const roles = authState?.userInfo?.resource_access[key]?.roles;
                const isValueInArray = roles.includes('etl_admin');
                return isValueInArray;
            }
        } else if (authState?.userInfo?.client_roles) {
            const roles = authState?.userInfo?.client_roles || [];
            const isValueInArray = roles.includes('etl_admin');
            return isValueInArray;
        }


        // const keys = Object.keys(authState?.userInfo?.resource_access);
        // for(let i=0; i < keys.length; i++) {
        //     if(keys[i]) {
        //         const roles = authState?.userInfo?.resource_access[keys[i]]?.roles;
        //         const isValueInArray = roles.includes('etl_admin');
        //         return isValueInArray;
        //     }
        // }
        return false;
    };

    const createMenuItem = (menuItem, index) => {
        const { displayName, url, prefix, suffix, source, role } = menuItem;

        return role == null || role == undefined || role == '' || getRoles() ? (
            <div key={`menu-${index}`} className="sidebar d-md-block bg-primary">
                <div className="simplebar-wrapper">
                    <NavLink to={url} activeStyle={activeStyle}>
                        {prefix ? prefix : <></>}
                        {displayName}
                        {suffix ? suffix : <></>}
                    </NavLink>
                </div>
                {source?.length > 0 && <div className="source-wrapper">{source.map(createMenuItem)}</div>}
            </div>
        ) : (
            <></>
        );
    };

    return <div className="navbar">{source.map(createMenuItem)}</div>;
};

export default NMenu;
