import { Suspense, useContext, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import "./App.scss";
import NMenu from "./components/nmenu";
import AppBlock, { BlockContext } from "./context/BlockContext";
import AppModal from "./context/ModalContext";
import Headers from "./layout/header";
import { routes } from "./routes";
import Footers from "./layout/footer";
import Sider from "antd/lib/layout/Sider";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./store/store";
import { authService } from "./services/auth.service";
import metadata from "../package.json";
import moment from "moment";
import { CoreActions } from "./redux-state/core/core-actions";
import { ComponentHelper } from "./helper/component.helper";
import { APP_THEME, APP_HEADER_LOGO } from "./constants";

const AppMenus = () => {
  return (
    <Sider>
      <div className="logo" style={APP_THEME == "light" ? { background: "#FFF" } : {}}>
        <img src={APP_HEADER_LOGO || "/img/etl252_red_white.png"} />
      </div>
      <div className="left-layout">
        <NMenu
          activeStyle={{ background: "#ffffff" }}
          source={[
            {
              displayName: "Connection",
              url: "/admin/connection",
              prefix: (
                <img
                  src="/img/Connection_24X24_Ac.png"
                  style={{ marginRight: 15 }}
                />
              ),
            },
            {
              displayName: "Task",
              url: "/admin/task",
              prefix: <img src="/img/Task.png" style={{ marginRight: 15 }} />,
            },
            // {
            //   displayName: "Jobs",
            //   url: "/admin/jobs",
            //   prefix: <img src="/img/Task.png" style={{ marginRight: 15 }} />,
            // },
            {
              displayName: "Execution",
              url: "/admin/execution",
              prefix: (
                <img src="/img/Home_24X24_Ac.png" style={{ marginRight: 15 }} />
              ),
            },
            {
              displayName: "Gateway",
              url: "/admin/gateway",
              prefix: (
                <img
                  src="/img/Gateway_24X24_Ac.png"
                  style={{ marginRight: 15 }}
                />
              ),
            },
            {
              displayName: "Audit log",
              role: "etlg_admin",
              url: "/admin/audit-log",
              prefix: (
                <img src="/img/log_24X24.png" style={{ marginRight: 15 }} />
              ),
            },
            // {
            //   displayName: "Sql",
            //   url: "/admin/sql",
            //   prefix: (
            //     <img
            //       src="/img/Connection_24X24_Ac.png"
            //       style={{ marginRight: 15 }}
            //     />
            //   ),
            // },
          ]}
        ></NMenu>
      </div>
      <span className="version_name">Version {metadata.version}</span>
    </Sider>
  );
};

const AppContent = () => {
  const dispatch = useDispatch();
  const blockView = useContext(BlockContext);
  const { authState } = useSelector((state) => state.core);
  const { authencation, expiresInDate } = authState;

  useEffect(() => {
    if (authencation) {
      // Нэвтэрцэн хэрэглэгчийн refresh token ийн хугацаа дуусан эсэхийн шалгах
      if (moment(expiresInDate).isBefore(moment())) {
        onLogout();
      } else {
        const internalId = setInterval(() => {
          // Нэвтэрцэн хэрэглэгчийн refresh token ийн хугацаа дуусан эсэхийн давтан шалгалт хийх 1 секунд тутамд
          if (moment(expiresInDate).isBefore(moment())) {
            clearInterval(internalId);
            onLogout();
          }
        }, 1000);

        return () => {
          clearInterval(internalId);
        };
      }
    }
  });

  const onLogout = () => {
    blockView.block();
    authService.logOut(authState.refresh_token).then(
      (response) => {
        dispatch(CoreActions.setAuthReset());
        ComponentHelper.navigateAuthencationDirect(window.location.origin);
        blockView.unblock();
      },
      (error) => {
        blockView.unblock();
      }
    );
  };
  return (
    <div className="app">
      <div className="middle-container">
        {authencation && AppMenus()}
        <div
          className="content"
          style={{
            width: authencation ? "calc(100% - 256px)" : "calc(100%)",
            left: authencation ? "256px" : "256px",
          }}
        >
          {authencation && <Headers onLogout={() => onLogout()} />}
          <Suspense fallback>
            {authencation && (
              <div className="main-content">
                {renderRoutes(routes.primaryRoutes)}
              </div>
            )}
            {renderRoutes(routes.publicRoutes)}
          </Suspense>
          <Footers />
        </div>
      </div>
    </div>
  );
};

function App() {
  return (
    <>
      <Provider store={store}>
        <AppContent></AppContent>
      </Provider>
      <AppModal></AppModal>
      <AppBlock></AppBlock>
    </>
  );
}

export default App;
