import React from "react";
import { Route, Switch } from "react-router";

const Home = React.lazy(() => import("../modules/dashboard/screen/home"));
const routesAdmin = React.lazy(() => import("../modules/admin/index"));

export default function PrivateRoutes() {
  return (
    <Switch>
      <Route path="/admin" component={routesAdmin} />
    </Switch>
  );
}
