import { Footer } from "antd/lib/layout/layout";
import moment from "moment";
import { APP_COPYRIGHT } from "../constants";

const Footers = (props) => {
  const currentYear = moment().year();
  let copyright = (APP_COPYRIGHT || "Software Consulting Service LLC © {currentYear}").replace("{currentYear}", currentYear)
  return <Footer><p>{copyright}</p></Footer>;
};

export default Footers;
