import React from "react";
import { Route, Switch } from "react-router";

const Home = React.lazy(() => import("../modules/dashboard/screen/home"));

export default function PublicRoutes() {
  return (
    <Switch>
      {/** Үндсэн нэвтрэх хуудас юм уу үндсэн нүүр хуудасны дуудах Component тохируулаж өгөх */}
      <Route path="/" component={Home} />
    </Switch>
  );
}
